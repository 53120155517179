import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { dark_logo, logo, shortTitle, title } from '../config/Config'
import { Link } from 'react-router-dom'
import { Copyright, Facebook, Instagram, KeyboardArrowUp, Telegram, Twitter } from '@mui/icons-material'

const Footer = () => {


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  

  return (
    <footer>
      <Row>

        <Col lg={3}>
          <div className='footer-logo-sec'>
            <img src={logo} alt={title + 'logo'} />
            <img src={dark_logo} alt={title + 'logo'} />
            <p> {title} is a Block Explorer and Analytics Platform for GTC Smart Chain</p>
          </div>
        </Col>

        <Col lg={3}>
          <div className='footer-link'>
            <h4> Useful links</h4>
            <ul className='nav flex-column'>
              <li className='nav-item'>
                <Link to="#" className="nav-link" > {shortTitle} Guide </Link>
              </li>
              <li className='nav-item'>
                <Link to="#" className="nav-link" > {shortTitle}-20 Dev Portal </Link>
              </li>
            </ul>
          </div>
        </Col>


        <Col lg={3}>
          <div className='footer-link'>
            <h4> {shortTitle}-20 scan</h4>
            <ul className='nav flex-column'>
              <li className='nav-item'>
                <Link to="#" className="nav-link" > Contact Us </Link>
              </li>
              <li className='nav-item'>
                <Link to="#" className="nav-link" > Privacy Policy </Link>
              </li>
              <li className='nav-item'>
                <Link to="#" className="nav-link" > Terms and Conditions </Link>
              </li>
            </ul>
          </div>
        </Col>

        <Col lg={3}>
          <div className='footer-link'>
            <h4> social media</h4>

            <div className='media__links'>
              <Link to="#"> <Facebook /> </Link>
              <Link to="#"> <Twitter /> </Link>
              <Link to="#"> <Instagram /> </Link>
              <Link to="#"> <Telegram /> </Link>
            </div>
          </div>
        </Col>

      </Row>


      <div className='copy__sf'>
        <p className='mb-0'> Copyright <Copyright style={{fontSize: '19px'}}/> {(new Date().getFullYear())} {title}. All Rights Reserved. </p>
        <button type='button' className='btn' onClick={scrollToTop}><KeyboardArrowUp /> Back to Top</button>
      </div>
    </footer>
  )
}

export default Footer