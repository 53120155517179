import React, { useEffect } from 'react'

const Login = (props:any) => {


  useEffect(() => {
    document.title = props.pageTitle;
  })

  return (
    <div>Login</div>
  )
}

export default Login