import React, { useEffect, useState } from 'react';
import SearchBar from './SearchBar';
import { AccessTimeSharp, BoltSharp, CheckCircleSharp, CheckSharp, ContentCopySharp, HelpOutlineSharp, LaunchSharp, TipsAndUpdatesOutlined } from '@mui/icons-material';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { api_url, gasCalculate, shortLogo, shortTitle, timeSince, timestampToDate, title } from '../config/Config';
import _fetch from '../config/api';




const HashDetails = () => {

    const {hash} = useParams();

    console.log('hash',hash );
    

    const [collapse, setCollapse] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const toggleContent = () => {
        setShowContent(true);

        const timer = setTimeout(() => {
            setShowContent(false)
        }, 1000);

        return () => clearTimeout(timer);
    };
    const copyToClipboard = (address: any, message: any) => {
        var textField = document.createElement('textarea')
        textField.innerText = address;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    const extraData = `0x`;


    const collapseToggle = () => {
        setCollapse(!collapse);
    };
    const[currentBlock,setcurrentBlock] = useState(0)
    const [data,setdata] = useState({blockHash:'',blockNumber:0,from:'',gas:"",gasPrice:"",gasUsed:'',hash:'',input:'',to:"",timeStamp:'',nonce:'',value:0})
    const getData = async () => {
        // setloading(true);
        let data = await _fetch(`${api_url}transaction/allTransactions?field=hash&value=${hash}`, "GET", {})
        if (data?.status === "success") {
            setdata(data?.data?.transactions?.[0]);
            setcurrentBlock(data?.data?.currentBlock?.blockNumber);
            
            // setloading(false);
        }
    }

    useEffect(() => {
        getData();
    },[])

    return (
        <main className='main___next'>

            <SearchBar titleTxt={'Transaction Details'} />

            <div className='card_outer_blocks'>
                <div className='card default_card_common blocks__card'>
                    <ul className='block__sd'>
                        <li>
                            <div className='left_heading'>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>The slot for which this block is created</Tooltip>}
                                >
                                    <HelpOutlineSharp />
                                </OverlayTrigger>

                                Transactions Hash:
                            </div>

                            <div className='right__a'>
                                <p>
                                    {data?.hash}
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                <p className='txt-nowrap mb-0'>  {showContent ? 'Copied!' : 'Copy address'}</p>
                                            </Tooltip>
                                        }>
                                        <button type='button' className='btn copybtn' onClick={() => { copyToClipboard(data?.hash, 'Copied successfully!'); toggleContent() }}>
                                            {showContent ? <CheckSharp /> : <ContentCopySharp />}
                                        </button>

                                    </OverlayTrigger>
                                </p>
                            </div>
                        </li>

                        <li>
                            <div className='left_heading'>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>the status of the transactions</Tooltip>}
                                >
                                    <HelpOutlineSharp />
                                </OverlayTrigger>

                                Status:
                            </div>

                            <div className='right__a'>
                                <span className='badge__btn success-badge bg-success bg-opacity-10 border border-success border-opacity-25 text-green-600 fw-medium text-start text-wrap'> <CheckCircleSharp /> Success</span>
                            </div>
                        </li>

                        <li>
                            <div className='left_heading'>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was produced.</Tooltip>}
                                >
                                    <HelpOutlineSharp />
                                </OverlayTrigger>
                                Block:
                            </div>

                            <div className='right__a'>
                                <p className='d-flex align-items-center'>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                <p className='txt-nowrap mb-0'> Click to view transactions</p>
                                            </Tooltip>
                                        }>
                                        <Link className='inner__link me-1 ms-1' to={"/block/"+data?.blockNumber}> {data?.blockNumber}</Link>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                <p className='txt-nowrap mb-0'> Number of blocks validated since</p>
                                            </Tooltip>
                                        }>
                                        <span className='badge__btn badge bg-secondary bg-opacity-10 border border-secondary border-opacity-25 fw-medium text-start text-wrap'>{currentBlock - data?.blockNumber} Block Confirmations</span>
                                    </OverlayTrigger>


                                </p>
                            </div>
                        </li>

                        <li>
                            <div className='left_heading'>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>The date and time at which a transaction is validated.</Tooltip>}
                                >
                                    <HelpOutlineSharp />
                                </OverlayTrigger>
                                Timestamp:
                            </div>

                            <div className='right__a'>
                                <p> <AccessTimeSharp /> {timeSince(data?.timeStamp)} ago ({timestampToDate(data?.timeStamp)})</p>
                            </div>
                        </li>

                    </ul>


                    <ul className='block__sd'>

                        <li className='pb-2 pt-2'>

                            <div className='left_heading'>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>Highlighted events of the transaction.</Tooltip>}
                                >
                                    <BoltSharp className='active__svg' />
                                </OverlayTrigger>
                                Transaction Action:
                            </div>

                            <div className='right__a'>
                                <p>
                                    Transfer {data?.value/1000000000000000000} {shortTitle} To
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                <p className='txt-nowrap mb-0'> {data?.to} </p>
                                            </Tooltip>
                                        }>
                                        <Link className='inner__link ms-1' to="#"> {(data?.to && data?.to?.length > 15) && (data?.to?.substring(0, 5) + '....' + data?.to?.substring(data?.to?.length - 5))} </Link>
                                    </OverlayTrigger>
                                </p>
                            </div>
                        </li>
                    </ul>

                    <ul className='block__sd'>

                        <li className='pb-2 pt-2'>
                            <div className='left_heading'>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>Sponsored banner advertisement.</Tooltip>}
                                >
                                    <HelpOutlineSharp/>
                                </OverlayTrigger>

                                Sponsored:
                            </div>

                            <div className='right__a'>
                                <p>  </p>
                            </div>
                        </li>
                    </ul>

                    <ul className='block__sd'>

                        <li className='pb-2 pt-2'>
                            <div className='left_heading'>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>The sending party of the transaction.</Tooltip>}
                                >
                                    <HelpOutlineSharp />
                                </OverlayTrigger>

                                From:
                            </div>

                            <div className='right__a'>
                                <p>
                                    <Link to="" className='inner__link'>{data?.from}</Link>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                <p className='txt-nowrap mb-0'>  {showContent ? 'Copied!' : 'Copy address'}</p>
                                            </Tooltip>
                                        }>
                                        <button type='button' className='btn copybtn' onClick={() => { copyToClipboard(data?.from, 'Copied successfully!'); toggleContent() }}>
                                            {showContent ? <CheckSharp /> : <ContentCopySharp />}
                                        </button>

                                    </OverlayTrigger>
                                </p>
                            </div>
                        </li>

                        <li className='pb-2 pt-2'>
                            <div className='left_heading'>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>The receiving party of the transaction (could be a contract address).</Tooltip>}
                                >
                                    <HelpOutlineSharp />
                                </OverlayTrigger>

                                To:
                            </div>

                            <div className='right__a'>
                                <p>
                                    <Link to="" className='inner__link'>{data?.to}</Link>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                <p className='txt-nowrap mb-0'>  {showContent ? 'Copied!' : 'Copy address'}</p>
                                            </Tooltip>
                                        }>
                                        <button type='button' className='btn copybtn' onClick={() => { copyToClipboard(data?.to, 'Copied successfully!'); toggleContent() }}>
                                            {showContent ? <CheckSharp /> : <ContentCopySharp />}
                                        </button>

                                    </OverlayTrigger>
                                </p>
                            </div>
                        </li>

                    </ul>


                    <ul className='block__sd last__block__sd'>

                        <li>
                            <div className='left_heading'>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>The value being transacted in {shortTitle} and fiat value. Note: You can click the fiat value (if available) to see historical value at the time of transaction.</Tooltip>}
                                >
                                    <HelpOutlineSharp />
                                </OverlayTrigger>

                                Value:
                            </div>

                            <div className='right__a'>
                                <img src={shortLogo} alt={title + 'Logo'} width="24px" />
                                <p className='ms-2'>  {data?.value/1000000000000000000} {shortTitle}</p>
                            </div>
                        </li>

                        <li>
                            <div className='left_heading'>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip> Amount paid to the validator for processing the transaction.</Tooltip>}
                                >
                                    <HelpOutlineSharp />
                                </OverlayTrigger>
                                Transaction Fee:
                            </div>

                            <div className='right__a'>
                                <p>{gasCalculate(data?.gasUsed,data?.gasPrice)}</p>
                            </div>
                        </li>

                        <li>
                            <div className='left_heading'>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>The sending party of the transaction.</Tooltip>}
                                >
                                    <HelpOutlineSharp />
                                </OverlayTrigger>
                                Size:
                            </div>

                            <div className='right__a'>
                                <p>0.000063 {shortTitle}</p>
                            </div>
                        </li>

                        <li>
                            <div className='left_heading'>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>Cost per unit of gas specified for the transaction, in {shortTitle} and Gwei. The higher the gas price the higher chance of getting included in a block.</Tooltip>}
                                >
                                    <HelpOutlineSharp />
                                </OverlayTrigger>
                                Gas Price:
                            </div>

                            <div className='right__a'>
                                <p> {data?.gasPrice} Gwei <span>(0.00000002) {shortTitle}</span></p>
                            </div>
                        </li>


                    </ul>
                </div>

                <div className='card default_card_common blocks__card mt-3 mb-3'>
                    <ul className={collapse ? 'collaspace__data block__sd last__block__sd inff' : 'inff block__sd last__block__sd'}>
                        <li className='pb-0 onclick_btn' onClick={collapseToggle}>
                            <div className='left_heading'>More Details: </div>

                            <div className='right__a'>
                                <p> {collapse ? "- Click to show Less" : " + Click to show More"}</p>
                            </div>
                        </li>

                        <div className='collapseData collapseData__2'>
                            <li className='mt-2'>
                                <div className='left_heading'>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip>Closing price of {shortTitle} on date of transaction (Mar-05-2024).</Tooltip>}
                                    >
                                        <HelpOutlineSharp />
                                    </OverlayTrigger>   {shortTitle} Price:
                                </div>

                                <div className='right__a'>
                                    <p>$394.50 / {shortTitle}</p>
                                </div>
                            </li>

                            <li className='mt-2'>
                                <div className='left_heading'>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip>Maximum amount of gas allocated for the transaction & the amount eventually used. Normal {shortTitle} transfers involve 21,000 gas units while contracts involve higher values. </Tooltip>}
                                    >
                                        <HelpOutlineSharp />
                                    </OverlayTrigger>   Gas Limit & Usage by Txn:
                                </div>

                                <div className='right__a'>
                                    <p>

                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip>the amount of Gas supplied for this transaction to happen </Tooltip>}
                                        >
                                            <span className='d-inline-block me-2'>21,000</span>
                                        </OverlayTrigger>

                                        /
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip>the amount of Gas used by this specific transaction alone </Tooltip>}
                                        >
                                            <span className='d-inline-block ms-2'>21,000 (100%)</span>
                                        </OverlayTrigger>
                                    </p>

                                </div>
                            </li>

                            <li>
                                <div className='left_heading'>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip>Total amount of {shortTitle} burnt from this tx.</Tooltip>}
                                    >
                                        <HelpOutlineSharp />
                                    </OverlayTrigger>

                                    Burnt Fees:
                                </div>

                                <div className='right__a'>
                                    <p>🔥 0.012545738933538983 {shortTitle}
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    <p className='txt-nowrap mb-0'> Open Fee Burn Transactions</p>
                                                </Tooltip>
                                            }>
                                            <Link className='inner__link ms-1 me-1' to="#"> <LaunchSharp /> </Link>
                                        </OverlayTrigger>

                                    </p>
                                </div>
                            </li>

                            <li>
                                <div className='left_heading'>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip>Other data related to this transaction.</Tooltip>}
                                    >
                                        <HelpOutlineSharp />
                                    </OverlayTrigger>

                                    Other Attributes:
                                </div>

                                <div className='right__a'>
                                    <p>
                                        <span className='badge__btn badge bg-secondary bg-opacity-10 border border-secondary border-opacity-25 fw-medium text-start text-wraps'>Nonce: 31</span>
                                        <span className='badge__btn badge bg-secondary bg-opacity-10 border border-secondary border-opacity-25 fw-medium text-start text-wraps ms-2'>Position In Block: 141</span>
                                    </p>
                                </div>
                            </li>

                            <li className='align-items-start'>
                                <div className='left_heading'>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip>Additional data included for this transaction. Commonly used as part of contract interaction or as a message sent to the recipient.</Tooltip>}
                                    >
                                        <HelpOutlineSharp />
                                    </OverlayTrigger>

                                    Input Data:
                                </div>

                                <div className='right__a'>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip>the binary data that formed the input to the transaction, either the input data if it was a message call or the contract initialisation if it was a contract creation.</Tooltip>}
                                    >
                                        <textarea className='form-control extradate__txt extradate__txt_2' value={extraData} disabled></textarea>
                                    </OverlayTrigger>
                                </div>
                            </li>

                        </div>

                    </ul>


                </div>


                <div className='card default_card_common blocks__card mt-3 mb-3'>
                    <ul className="block__sd last__block__sd inff">
                        <li className='pb-0'>
                            <div className='left_heading'>
                            <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>Private note to keep track of the transaction. Only viewable to {title}'s user who assign them.</Tooltip>}
                                >
                                    <HelpOutlineSharp />
                                </OverlayTrigger>

                            Private Note:
                                 </div>

                            <div className='right__a'>
                                <p> To access the <b>Private Note</b> feature, you must be <Link to="/login" className='inner__link'>Logged In</Link></p>
                            </div>
                        </li>
                    </ul>


                </div>

                <p className='tip__sec'> <TipsAndUpdatesOutlined /> A transaction is a cryptographically signed instruction that changes the blockchain state. Block explorers track the details of all transactions in the network. Learn more about transactions in our <Link to={''} className='inner__link'>Knowledge Base</Link>.</p>
            </div>



        </main>
    );
}

export default HashDetails;
