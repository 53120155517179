import React from 'react';
import './Assets/Scss/Style.scss';
import { BrowserRouter as Router, Routes, Route, Outlet, } from "react-router-dom";
import Login from './Public/Users/Login';
import Index from './Public/HomePage/Index';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import { titleDescription } from './config/Config';
import TransactionsAll from './Public/TransactionsAll';
import BlocksAll from './Public/BlocksAll';
import ContractTnx from './Public/ContractTnx';
import BlockDetails from './Public/BlockDetails';
import HashDetails from './Public/HashDetails';
import Error from './Public/Error';
import AddressView from './Public/AddressView';

function App() {




  // ? Login page.....
  function LoginLayout() {
    return (
      <>
        <Outlet />
      </>
    );
  }

  // Public page.....
  function PublicLayout() {
    return (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    );
  }





  return (
    <>

      <Router>
        <Routes>


          {/* header and footer is removed this layout */}
          <Route element={<LoginLayout />}>
            <Route path='/login' element={<Login pageTitle={`Login | ${titleDescription}`} />} />
          </Route>


          {/* All Public pages___ */}
          <Route element={<PublicLayout />}>
            <Route path='/error' element={<Error />} />
            <Route path='*' element={<Index />} />
            <Route path='/txs' element={<TransactionsAll />} />
            <Route path='/blocks' element={<BlocksAll />} />
            <Route path='/txscontract' element={<ContractTnx />} />
            <Route path='/block/:id' element={<BlockDetails />} />
            <Route path='/tx/:hash' element={<HashDetails />} />
            <Route path='/address/:id' element={<AddressView />} />

          </Route>




        </Routes>
      </Router>


    </>
  );
}

export default App;
