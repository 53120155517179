import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AscTimestampTimer, api_url, gasCalculate, shortTitle } from '../../../config/Config';
import { East, ViewInAr } from '@mui/icons-material';
import Skeleton from 'react-loading-skeleton';
import _fetch from '../../../config/api';

const Blocks = () => {
    const [blockData, setblockData] = useState([]);
    const [gasFee, setgasFee] = useState(0);

    const getblockData = async () => {
        setloading(true);
        let data = await _fetch(`${api_url}block?pageNumber=1&pageSize=6`, "GET", {})
        if (data?.status === "success") {
            setblockData(data?.data?.latest_blocks);
            setgasFee(data?.data?.gasPrice);
            setloading(false);
        }
    }
    const [loading, setloading] = useState(true);

    useEffect(() => {
        getblockData();

        // const timer = setTimeout(() => {
        //     setloading(false);
        // }, 5000); // 5 seconds in milliseconds

        // return () => clearTimeout(timer);

    }, []);


    return (
        <div className='card default_card tabes__card'>
            <div className='card-header'>
                <h5>Latest Block</h5>
            </div>

            <div className='card-body'>
                {loading && <ul className='table__list'>
                    <li>
                        <div className='i_first'>

                            <Skeleton
                                circle
                                height="100%"
                                containerClassName="avatar-skeleton"
                            />
                            <div className='i_pragraph w-100'>
                                <Skeleton count={2}
                                    containerClassName="table__6__"
                                    className="home_skeleton__hn"
                                />
                            </div>
                        </div>

                        <div className='i_middle'>
                            <Skeleton count={2}
                                containerClassName="table__6__"
                                className="home_skeleton__hn home_skeleton__center"
                            />
                        </div>

                        <div className='i_last'>
                            <Skeleton count={1}
                                containerClassName="table__6__"
                                className="home_skeleton__hn"
                            />
                        </div>
                    </li>
                    <li>
                        <div className='i_first'>

                            <Skeleton
                                circle
                                height="100%"
                                containerClassName="avatar-skeleton"
                            />
                            <div className='i_pragraph w-100'>
                                <Skeleton count={2}
                                    containerClassName="table__6__"
                                    className="home_skeleton__hn"
                                />
                            </div>
                        </div>

                        <div className='i_middle'>
                            <Skeleton count={2}
                                containerClassName="table__6__"
                                className="home_skeleton__hn home_skeleton__center"
                            />
                        </div>

                        <div className='i_last'>
                            <Skeleton count={1}
                                containerClassName="table__6__"
                                className="home_skeleton__hn"
                            />
                        </div>
                    </li>
                    <li>
                        <div className='i_first'>

                            <Skeleton
                                circle
                                height="100%"
                                containerClassName="avatar-skeleton"
                            />
                            <div className='i_pragraph w-100'>
                                <Skeleton count={2}
                                    containerClassName="table__6__"
                                    className="home_skeleton__hn"
                                />
                            </div>
                        </div>

                        <div className='i_middle'>
                            <Skeleton count={2}
                                containerClassName="table__6__"
                                className="home_skeleton__hn home_skeleton__center"
                            />
                        </div>

                        <div className='i_last'>
                            <Skeleton count={1}
                                containerClassName="table__6__"
                                className="home_skeleton__hn"
                            />
                        </div>
                    </li>
                    <li>
                        <div className='i_first'>

                            <Skeleton
                                circle
                                height="100%"
                                containerClassName="avatar-skeleton"
                            />
                            <div className='i_pragraph w-100'>
                                <Skeleton count={2}
                                    containerClassName="table__6__"
                                    className="home_skeleton__hn"
                                />
                            </div>
                        </div>

                        <div className='i_middle'>
                            <Skeleton count={2}
                                containerClassName="table__6__"
                                className="home_skeleton__hn home_skeleton__center"
                            />
                        </div>

                        <div className='i_last'>
                            <Skeleton count={1}
                                containerClassName="table__6__"
                                className="home_skeleton__hn"
                            />
                        </div>
                    </li>
                    <li>
                        <div className='i_first'>

                            <Skeleton
                                circle
                                height="100%"
                                containerClassName="avatar-skeleton"
                            />
                            <div className='i_pragraph w-100'>
                                <Skeleton count={2}
                                    containerClassName="table__6__"
                                    className="home_skeleton__hn"
                                />
                            </div>
                        </div>

                        <div className='i_middle'>
                            <Skeleton count={2}
                                containerClassName="table__6__"
                                className="home_skeleton__hn home_skeleton__center"
                            />
                        </div>

                        <div className='i_last'>
                            <Skeleton count={1}
                                containerClassName="table__6__"
                                className="home_skeleton__hn"
                            />
                        </div>
                    </li>
                    <li>
                        <div className='i_first'>

                            <Skeleton
                                circle
                                height="100%"
                                containerClassName="avatar-skeleton"
                            />
                            <div className='i_pragraph w-100'>
                                <Skeleton count={2}
                                    containerClassName="table__6__"
                                    className="home_skeleton__hn"
                                />
                            </div>
                        </div>

                        <div className='i_middle'>
                            <Skeleton count={2}
                                containerClassName="table__6__"
                                className="home_skeleton__hn home_skeleton__center"
                            />
                        </div>

                        <div className='i_last'>
                            <Skeleton count={1}
                                containerClassName="table__6__"
                                className="home_skeleton__hn"
                            />
                        </div>
                    </li>
                </ul>}
                {!loading && <ul className='table__list'>
                    {blockData?.map((val: any) => (
                        <li>
                            <div className='i_first'>

                                <>
                                    <div className='icon__first'>
                                        <ViewInAr />
                                    </div>
                                </>


                                <div className='i_pragraph w-100'>


                                    <>
                                    <Link to={'/block/'+ (val?.number)}  className='btn arrow__btn'>  <p> {val?.number}</p></Link>

                                       
                                        <p> {AscTimestampTimer(val?.timestamp,val?.number)} <span id={val?.number}></span></p>
                                    </>


                                </div>
                            </div>


                            <div className='i_middle'>


                                <>
                                    <p>

                                        Validated By
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    <p className='txt-nowrap mb-0'>{val?.miner}</p>
                                                </Tooltip>
                                            }>

                                            <Link className='inner__link ms-1' to="#">{(val?.miner && val?.miner.length > 15) && (val?.miner.substring(0, 5) + '....' + val?.miner.substring(val?.miner.length - 5))} </Link>
                                        </OverlayTrigger>
                                    </p>
                                    <p>
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    <p className='txt-nowrap mb-0'>Transactions in this block</p>
                                                </Tooltip>
                                            }>
                                            <Link className='inner__link me-2' to="">{val?.transactions?.length} txns</Link>
                                        </OverlayTrigger>

                                        in 3 secs</p>
                                </>



                            </div>

                            <div className='i_last'>


                                <>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                Block Reward
                                            </Tooltip>
                                        }>
                                        <p>{gasCalculate(val?.gasUsed, gasFee)} {shortTitle}</p>
                                    </OverlayTrigger>
                                </>





                            </div>


                        </li>
                    ))}


                </ul>}
            </div>

            <div className='card-footer'>
                <Link to="/blocks">VIEW ALL BLOCKS <East /> </Link>
            </div>
        </div>
    );
}

export default Blocks;
