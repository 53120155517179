import React, { useEffect, useState } from 'react';
import SearchBar from './SearchBar';
import { AccessTimeSharp, CheckSharp, ContentCopySharp, HelpOutlineSharp, KeyboardArrowLeftSharp, KeyboardArrowRightSharp, LaunchSharp, TipsAndUpdatesOutlined } from '@mui/icons-material';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { api_url, gasCalculate, shortTitle, timeSince, timestampToDate } from '../config/Config';
import _fetch from '../config/api';



const BlockDetails = (props: any) => {


    const { id } = useParams();
    console.log('sdfsd', id);

    const [showContent, setShowContent] = useState(false);
    const [collapse, setCollapse] = useState(false);

    const toggleContent = () => {
        setShowContent(true);

        const timer = setTimeout(() => {
            setShowContent(false)
        }, 1000); // 1 seconds in milliseconds

        return () => clearTimeout(timer);
    };

    const [blockData, setblockData] = useState({number:0,transactions:[],hash:'',miner:'',gasUsed:0,difficulty:0,totalDifficulty:0,size:0,gasLimit:0,extraData:'',parentHash:'',sha3Uncles:'',nonce:'',timestamp:''});
    const [gasFee, setgasFee] = useState(0);

    const getblockData = async () => {
        // setloading(true);
        let data = await _fetch(`${api_url}block?blockNo=${id}`, "GET", {})
        if (data?.status === "success") {
            setblockData(data?.data?.block);
            setgasFee(data?.data?.gasPrice);
            // setloading(false);
        }
    }


    const copyToClipboard = (address: any, message: any) => {
        var textField = document.createElement('textarea')
        textField.innerText = address;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }
    const extraData = `Hex: ${blockData?.extraData}`;


    const collapseToggle = () => {
        setCollapse(!collapse);
    };

    useEffect(() => {
        getblockData()
    },[id])


    return (
        <main className='main___next'>

            <SearchBar titleTxt={'Block #'+ blockData?.number} />

            <div className='card_outer_blocks'>
                <div className='card default_card_common blocks__card'>
                    <ul className='block__sd'>
                        <li>
                            <div className='left_heading'>
                                <HelpOutlineSharp />
                                Block Height:
                            </div>

                            <div className='right__a'>
                                <p>{blockData?.number}</p>

                                <div className='arrow__btn_group'>
                                    <Link to={'/block/'+ (Number(id) - 1)}  className='btn arrow__btn'> <KeyboardArrowLeftSharp /></Link>
                                    <Link  to={'/block/'+ (Number(id) + 1)}  className='btn arrow__btn'> <KeyboardArrowRightSharp /></Link>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div className='left_heading'>
                                <HelpOutlineSharp />  Timestamp:
                            </div>

                            <div className='right__a'>
                                <p> <AccessTimeSharp /> {timeSince(blockData?.timestamp)} ago ({timestampToDate(blockData?.timestamp)})</p>
                            </div>
                        </li>

                        <li>
                            <div className='left_heading'>
                                <HelpOutlineSharp />  Transactions:
                            </div>

                            <div className='right__a'>
                                <p>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                <p className='txt-nowrap mb-0'> Click to view transactions</p>
                                            </Tooltip>
                                        }>
                                        <Link className='inner__link me-1 ms-1' to="#"> {blockData?.transactions?.length} transactions</Link>
                                    </OverlayTrigger>

                                    and

                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                <p className='txt-nowrap mb-0'> Click to view internal transactions</p>
                                            </Tooltip>
                                        }>
                                        <Link className='inner__link ms-1 me-1' to="#"> 0 contract internal transactions</Link>
                                    </OverlayTrigger>

                                    in this block</p>
                            </div>
                        </li>

                    </ul>


                    <ul className='block__sd'>

                        <li>
                            <div className='left_heading'>
                                <HelpOutlineSharp />   Validated By:
                            </div>

                            <div className='right__a'>
                                <p>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                <p className='txt-nowrap mb-0'> {blockData?.miner} </p>
                                            </Tooltip>
                                        }>
                                        <Link className='inner__link ms-1' to="#"> {(blockData?.miner && blockData?.miner.length > 15) && (blockData?.miner.substring(0, 8) + '....' + blockData?.miner.substring(blockData?.miner.length - 8))} </Link>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                <p className='txt-nowrap mb-0'>  {showContent ? 'Copied!' : 'Copy address'}</p>
                                            </Tooltip>
                                        }>

                                        <button type='button' className='btn copybtn' onClick={() => { copyToClipboard(blockData?.miner, 'From copied successfully!'); toggleContent() }}>
                                            {showContent ? <CheckSharp /> : <ContentCopySharp />}
                                        </button>

                                    </OverlayTrigger>

                                    in 3 secs</p>
                            </div>
                        </li>

                        <li>
                            <div className='left_heading'>
                                <HelpOutlineSharp />  Block Reward:
                            </div>

                            <div className='right__a'>
                                <p> {gasCalculate(blockData?.gasUsed, gasFee)} {shortTitle}</p>
                            </div>
                        </li>

                        <li>
                            <div className='left_heading'>
                                <HelpOutlineSharp />   Difficulty:
                            </div>

                            <div className='right__a'>
                                <p>{blockData?.difficulty}</p>
                            </div>
                        </li>

                        <li>
                            <div className='left_heading'>
                                <HelpOutlineSharp />  Total Difficulty:
                            </div>

                            <div className='right__a'>
                                <p>{blockData?.totalDifficulty}</p>
                            </div>
                        </li>

                        <li>
                            <div className='left_heading'>
                                <HelpOutlineSharp />  Size:
                            </div>

                            <div className='right__a'>
                                <p>{blockData?.size} bytes</p>
                            </div>
                        </li>

                    </ul>


                    <ul className='block__sd last__block__sd'>
                        <li>
                            <div className='left_heading'>
                                <HelpOutlineSharp />  Gas Used:
                            </div>

                            <div className='right__a'>
                                <p> {blockData?.gasUsed} ({((blockData?.gasUsed /blockData?.gasLimit ) * 100)?.toFixed(2) }%)</p>
                            </div>
                        </li>

                        <li>
                            <div className='left_heading'>
                                <HelpOutlineSharp />   Gas Limit:
                            </div>

                            <div className='right__a'>
                                <p>{blockData?.gasLimit}</p>
                            </div>
                        </li>

                        <li>
                            <div className='left_heading'>
                                <HelpOutlineSharp />   Burnt Fees:
                            </div>

                            <div className='right__a'>
                                <p>🔥 {gasCalculate(blockData?.gasUsed, gasFee) / 10} {shortTitle}
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                <p className='txt-nowrap mb-0'> Open Fee Burn Transactions</p>
                                            </Tooltip>
                                        }>
                                        <Link className='inner__link ms-1 me-1' to="#"> <LaunchSharp /> </Link>
                                    </OverlayTrigger>

                                </p>
                            </div>
                        </li>

                        <li className='align-items-start'>
                            <div className='left_heading'>
                                <HelpOutlineSharp />   Extra Data:
                            </div>

                            <div className='right__a'>
                                <textarea className='form-control extradate__txt' value={extraData} disabled>

                                </textarea>
                            </div>
                        </li>

                    </ul>
                </div>

                <div className='card default_card_common blocks__card mt-3 mb-3'>
                    <ul className={collapse ? 'collaspace__data block__sd last__block__sd inff' : 'inff block__sd last__block__sd'}>
                        <li className='pb-0 onclick_btn' onClick={collapseToggle}>
                            <div className='left_heading'>More Details: </div>

                            <div className='right__a'>
                                <p> {collapse ? "- Click to show Less" : " + Click to show More"}</p>
                            </div>
                        </li>

                        <div className='collapseData'>
                            <li className='mt-2'>
                                <div className='left_heading'>
                                    <HelpOutlineSharp />    Hash:
                                </div>

                                <div className='right__a'>
                                    <p>{blockData?.hash}</p>
                                </div>
                            </li>

                            <li>
                                <div className='left_heading'>
                                    <HelpOutlineSharp />    Parent Hash:
                                </div>

                                <div className='right__a'>
                                    <p>  {blockData?.parentHash}</p>
                                </div>
                            </li>

                            <li>
                                <div className='left_heading'>
                                    <HelpOutlineSharp />    Sha3Uncles:
                                </div>

                                <div className='right__a'>
                                    <p> {blockData?.sha3Uncles} </p>
                                </div>
                            </li>

                            <li>
                                <div className='left_heading'>
                                    <HelpOutlineSharp />     Nonce:
                                </div>

                                <div className='right__a'>
                                    <p> {blockData?.nonce} </p>
                                </div>
                            </li>
                        </div>

                    </ul>


                </div>

                <p className='tip__sec'> <TipsAndUpdatesOutlined /> Blocks are batches of transactions linked via cryptographic hashes. Any tampering of a block would invalidate all following blocks as all subsequent hashes would change. Learn more about this page in our Knowledge Base.</p>
            </div>


        </main>
    );
}

export default BlockDetails;
