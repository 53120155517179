import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { api_url, shortLogo, shortTitle } from '../../../config/Config';
import transaction_icon from '../../../Assets/Icon/networking.png';
import multiple_users from '../../../Assets/Icon/multiple-users.png';
import contracts__icon from '../../../Assets/Icon/smart-contracts.png';
import Skeleton from 'react-loading-skeleton';
import CountUp from 'react-countup';
import _fetch from '../../../config/api';



const Information = () => {

    const [price, setprice] = useState([]);
    const [priceLoader, setpriceLoader] = useState(true);

    const getPrice = async () => {
        setpriceLoader(true);
        let data = await _fetch(`${api_url}price/currentPrice`, "GET", {})
        if (data?.status === "success") {
            setprice(data?.data?.current_price);
            setpriceLoader(false)
        }
    }

    const [TransactionDetails, setTransactionDetails] = useState({ 'total_unique_recipients': 0, 'total_transactions': 0 });
    const [TransactionDetailsLoader, setTransactionDetailsLoader] = useState(true);
    const getTransaction = async () => {
        setTransactionDetailsLoader(true);
        let data = await _fetch(`${api_url}transaction/totalTransactionsByRecipient`, "GET", {})
        if (data?.status === "success") {
            setTransactionDetails(data?.data);
            setTransactionDetailsLoader(false);

        }
    }
    const [Transaction24Details, setTransaction24Details] = useState(0);
    const [Transaction24Detailsloader, setTransaction24Detailsloader] = useState(true);

    const get24Transaction = async () => {
        setTransaction24Detailsloader(true);
        let data = await _fetch(`${api_url}transaction/totalTodayTransactions`, "GET", {})
        if (data?.status === "success") {
            setTransaction24Details(data?.data?.total_transactions_24h);
            setTransaction24Detailsloader(false);

        }
    }


    const [loader, setloader] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setloader(false);
        }, 5000); // 5 seconds in milliseconds

        return () => clearTimeout(timer);
    }, []); // Empty dependency array ensures this effect runs only once

    useEffect(() => {
        getPrice();
        getTransaction();
        get24Transaction();
    }, [])
    return (
        <>
            <Row className='info__row'>
                <Col sm={12}>
                    <div className='card default_card price__card'>
                        <div className='card-header'>
                            <div className='card__icon'>
                                <img src={shortLogo} alt={shortTitle + 'exchange.logo'} width="100%" />
                            </div>

                            <div className='card-header-rd'>

                                {
                                    priceLoader ?
                                        <Skeleton count={2}
                                            containerClassName="Table-skeleton"
                                            className="slider-loading-skeleton home__skeleton"
                                        />
                                        :
                                        <>
                                            <p> {shortTitle} Price</p>

                                            <p> <span>${price} </span></p>
                                        </>
                                }

                            </div>
                        </div>

                        <div className='card-body'>
                            <ul>
                                <li>
                                    {
                                        loader ?
                                            <Skeleton count={2}
                                                containerClassName="Table-skeleton"
                                                className="slider-loading-skeleton home__skeleton2"
                                            />
                                            :
                                            <>
                                                <p> {shortTitle} Supply</p>
                                                <p>
                                                    <CountUp
                                                        duration={3.75}
                                                        decimal=","
                                                        end={200000} />
                                                </p>
                                            </>
                                    }
                                </li>

                                <li>

                                    {
                                        loader ?
                                            <Skeleton count={2}
                                                containerClassName="Table-skeleton"
                                                className="slider-loading-skeleton home__skeleton2"
                                            />
                                            :
                                            <>
                                                <p>Decimals</p>
                                                <p>
                                                    <CountUp
                                                        duration={3.75}
                                                        decimal=","
                                                        end={18} />
                                                </p>
                                            </>
                                    }


                                </li>
                            </ul>
                        </div>
                    </div>
                </Col>


                <Col lg={6}>
                    <div className='card default_card price__card'>
                        <div className='card-header'>
                            <div className='card__icon card__icon_2'>

                                <img src={multiple_users} alt={'transaction_icon'} width="100%" />
                            </div>


                            <div className='card-header-rd'>
                                {
                                    TransactionDetailsLoader ?
                                        <Skeleton count={2}
                                            containerClassName="Table-skeleton"
                                            className="slider-loading-skeleton home__skeleton"
                                        />
                                        :
                                        <>
                                            <p> Total Account</p>
                                            <p> <CountUp
                                                duration={3.75}
                                                decimal=","
                                                end={TransactionDetails?.total_unique_recipients} />
                                            </p>
                                        </>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='card default_card price__card mt-4'>
                        <div className='card-header'>
                            <div className='card__icon card__icon_2'>
                                <img src={contracts__icon} alt={'contracts__icon'} width="100%" />
                            </div>

                            <div className='card-header-rd'>
                                {
                                    loader ?
                                        <Skeleton count={2}
                                            containerClassName="Table-skeleton"
                                            className="slider-loading-skeleton home__skeleton"
                                        />
                                        :
                                        <>
                                            <p> Total Contracts</p>
                                            <p>  <CountUp
                                                duration={3.75}
                                                decimal=","
                                                end={2} />
                                            </p>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className='card default_card price__card'>
                        <div className='card-header'>
                            <div className='card__icon card__icon_2'>
                                <img src={transaction_icon} alt={'transaction_icon'} width="100%" />
                            </div>

                            <div className='card-header-rd'>
                                {
                                    TransactionDetailsLoader ?
                                        <Skeleton count={2}
                                            containerClassName="Table-skeleton"
                                            className="slider-loading-skeleton home__skeleton"
                                        />
                                        :
                                        <>
                                            <p> Total Transactions</p>
                                            <p>
                                                <CountUp
                                                    duration={3.75}
                                                    decimal=","
                                                    end={TransactionDetails?.total_transactions} />
                                            </p>
                                        </>
                                }
                            </div>
                        </div>

                        <div className='card-body card_body__height'>
                            <ul>

                                <li>
                                    {
                                        loader ?
                                            <Skeleton count={2}
                                                containerClassName="Table-skeleton"
                                                className="slider-loading-skeleton home__skeleton"
                                            />
                                            :
                                            <>
                                                <h6>Total 24 Hour Transactions</h6>
                                                <p>
                                                    <CountUp
                                                        duration={3.75}
                                                        decimal=","
                                                        end={Transaction24Details} />
                                                </p>
                                            </>
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                </Col>

            </Row>
        </>
    );
}

export default Information;
