import React, { useEffect, useState } from 'react';
import { CopyAll, East, HelpOutlineRounded, NorthEastRounded, Receipt, RemoveRedEye } from '@mui/icons-material';
import { Button, Col, Form, OverlayTrigger, Pagination, Popover, Row, Table, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AscTimestampTimer, timestampToDate, api_url, shortTitle } from '../config/Config';
import SearchBar from './SearchBar';
import _fetch from '../config/api';

const TransactionsAll = () => {

  const wallet__address = '0x9ab76ac67bd31404d025b02c2748a90d37b25457a844243051';

  const [TXSData, setTXSData] = useState([]);
  const [totalPages, settotalPages] = useState(0);
  const [limit, setlimit] = useState(10);
  const [page, setpage] = useState(1);
  const [loader, setloader] = useState(true);


  const getTXS = async () => {
    setloader(true);
    let data = await _fetch(`${api_url}transaction/allTransactions?page=${page}&page=${limit}`, "GET", {})
    if (data?.status === "success") {
      setTXSData(data?.data?.transactions);
      settotalPages(data?.data?.totalPages);
      setloader(false);
    }
  }
  useEffect(() => {

    getTXS();

  }, [limit, page])
  const popover = (
    <Popover id="popover-basic" className='table__popover' >

      <React.Fragment >
        <Popover.Header as="h3">Additional Info</Popover.Header>
        <Popover.Body >
          <div className='data-1'>
            <p className='data-type'> Status:-</p>
            <p className='status-value'>  Block Confirmations) </p>
          </div>

          <div className='data-1'>
            <p className='data-type'> Transaction Fee::-</p>
            <p className='status-value'> sd <span> 234234</span> </p>
          </div>


          <div className='data-1'>
            <p className='data-type'> Gas Info::-</p>
            <p className='status-value'> 234 </p>
            <p className='status-value'><span> 234234</span>  </p>
          </div>

          <div className='data-1'>
            <p className='data-type'> Nonce:-</p>
            <p className='status-value'> 234<span> (in the position )</span> </p>
          </div>

          <div className='last-data-1'>
            <Link to="/">See more details </Link>
          </div>
        </Popover.Body>
      </React.Fragment>
    </Popover>
  );



  const copyToClipboard = (address: any, message: any) => {
    var textField = document.createElement('textarea')
    textField.innerText = address;
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }



  return (
    <main className='main___next'>

      {/* sub header means header txt and searchbar */}

      <SearchBar titleTxt={'Transactions'} />


      {/* <div className='card___section'>
        <Row>
          <Col lg={3} sm={6}>
            <div className='card trans__card_details'>
              <p>Transactions (24H)</p>
              <h5>5,177,722</h5>
              <h6><NorthEastRounded/></h6>
              <Link to="" className='stretched-link'>  </Link>

            </div>
          </Col>

          <Col lg={3} sm={6}>
            <div className='card trans__card_details'>
              <p>pending Transactions</p>
              <h5>5,177,722</h5>
              <h6><NorthEastRounded/></h6>
              <Link to="" className='stretched-link'>  </Link>

            </div>
          </Col>



          <Col lg={3} sm={6}>
            <div className='card trans__card_details'>
              <p> network Transactions Fee (24H)</p>
              <h5>5,177,722</h5>
              <h6><NorthEastRounded/></h6>
              <Link to="" className='stretched-link'>  </Link>

            </div>
          </Col>


          <Col lg={3} sm={6}>
            <div className='card trans__card_details'>
              <p>Transactions (24H)</p>
              <h5>5,177,722</h5>
              <h6><NorthEastRounded/></h6>
              <Link to="" className='stretched-link'>  </Link>

            </div>
          </Col>
        </Row>
      </div> */}

      {/* table data starting */}

      <div className='table__box'>
        <div className='table__outer table__card'>
          <div className='table__outer__header'>
            <div className='table__txt-header'>
              <h5 className=''>More than {totalPages * limit} transactions found</h5>
              <p>( Showing the last 500k records )</p>
            </div>

            <div className='pagination__sx'>
              <Pagination>
                <Pagination.First onClick={() => setpage(1)}> {"First"}</Pagination.First>
                <Pagination.Prev onClick={() => { page > 1 && setpage(page - 1) }} />pre
                <Pagination.Item disabled> Page {page} of 10000 </Pagination.Item>
                <Pagination.Next onClick={() => { totalPages >= page && setpage(page + 1) }} />
                <Pagination.Last onClick={() => setpage(totalPages)} >{"Last"}</Pagination.Last>
              </Pagination>
            </div>

          </div>
          <div className=''>
            <Table>
              <thead>
                <tr>
                  <th> <HelpOutlineRounded style={{ fontSize: '18px' }} /></th>
                  <th>Txn Hash</th>
                  <th>Method <HelpOutlineRounded style={{ fontSize: '18px' }} /> </th>
                  <th>Block</th>
                  <th>Age</th>
                  <th>From</th>
                  <th></th>
                  <th>To</th>
                  <th>Value</th>
                  <th>Tnx Fee</th>
                </tr>
              </thead>

              <tbody className='tnx_list'>

                {TXSData?.map((val: any) => (
                  <tr>
                    <td className='bg__td_td' >
                      <OverlayTrigger trigger="focus" placement="right" overlay={popover}>
                        <button className='eye__button'> <RemoveRedEye /></button>
                      </OverlayTrigger>
                    </td>

                    <td className='bg__td_td' >
                      <Link to={"/tx/"+val?.hash} className='link_tag' > <h5> {(val?.hash?.length > 15) && val?.hash?.substring(0, 5) + '....' + val?.hash?.substring(val?.hash?.length - 5, val?.hash?.length)}</h5></Link>
                    </td>

                    <td>

                      <OverlayTrigger
                        placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Transfer</Tooltip>} >
                        <Button className='button_d' >Transfer</Button>
                      </OverlayTrigger>

                    </td>

                    <td> <Link to="#" className='link_tag' ><h5> {val?.blockNumber} </h5></Link></td>

                    <td>
                      <div className='times-area'>
                        {/* <div className='time'> 5 Secs ago</div> */}
                        <p> {AscTimestampTimer(val?.timeStamp, val?.blockNumber + 'tx')} <span id={val?.blockNumber + 'tx'}></span></p>

                        <div className='time-date'>{timestampToDate(val?.timestamp)}	</div>
                      </div>
                    </td>

                    <td>
                      <div className='id__from_token'>


                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={<Tooltip className='copy__value'> {val?.from}</Tooltip>}>
                          <Link to="#" className='link_tag' ><h5>{(val?.from?.length > 15) && val?.from?.substring(0, 5) + '....' + val?.from?.substring(val?.from?.length - 10, val?.from?.length)}</h5> </Link>

                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={<Tooltip>Copy Text</Tooltip>}
                        >
                          <button type='button' className='copy-token' onClick={() => { copyToClipboard(val?.from, 'From copied successfully!') }}> <CopyAll />   </button>

                        </OverlayTrigger>
                      </div>
                    </td>

                    <td>
                      <div className='TOP__riht'>
                        <div className='this_TO_this'><East /></div>
                      </div>
                    </td>

                    <td>
                      <div className='id__from_token'>

                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={<Tooltip> {val?.to}</Tooltip>}>
                          <Link to="#" className='link_tag'> <h5>  {(val?.to?.length > 15) ? <h5>{val?.to?.substring(0, 5) + '....' + val?.to?.substring(val?.to?.length - 10, val?.to?.length)}</h5> : <h5><Receipt />{val?.to?.substring(0, 5) + '....' + val?.to?.substring(val?.to?.length - 5, val?.to?.length)}</h5>}</h5> </Link>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={<Tooltip>Copy Text </Tooltip>}
                        >
                          <button type='button' className='copy-token' onClick={() => { copyToClipboard(val?.to, 'To copied successfully!') }}> <CopyAll />   </button>
                        </OverlayTrigger>
                      </div>
                    </td>

                    <td><h5>{val?.value / 1000000000000000000}<span className='ms-2'>{shortTitle}</span>	</h5>  </td>

                    <td>
                      <div className='trantion-area'>
                        <h5> 0</h5>
                      </div>
                    </td>
                  </tr>
                ))}


                {/* <tr>
                  <td className='bg__td_td' >
                    <OverlayTrigger trigger="focus" placement="right" overlay={popover}>
                      <button className='eye__button'> <RemoveRedEye /></button>
                    </OverlayTrigger>
                  </td>

                  <td className='bg__td_td' >
                    <Link to="#" className='link_tag' > <h5> {(wallet__address?.length > 15) && wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 5, wallet__address?.length)}</h5></Link>
                  </td>

                  <td>

                    <OverlayTrigger
                      placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Transfer</Tooltip>} >
                      <Button className='button_d' >Transfer</Button>
                    </OverlayTrigger>

                  </td>

                  <td> <Link to="#" className='link_tag' ><h5> 36694663 </h5></Link></td>

                  <td>
                    <div className='times-area'>
                      <div className='time'> 5 Secs ago</div>
                      <div className='time-date'>2023-03-24 7:55:23	</div>
                    </div>
                  </td>

                  <td>
                    <div className='id__from_token'>


                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip className='copy__value'> {wallet__address}</Tooltip>}>
                        <Link to="#" className='link_tag' ><h5>{(wallet__address?.length > 15) && wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 10, wallet__address?.length)}</h5> </Link>

                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Copy Text</Tooltip>}
                      >
                        <button type='button' className='copy-token' onClick={() => { copyToClipboard(wallet__address, 'From copied successfully!') }}> <CopyAll />   </button>

                      </OverlayTrigger>
                    </div>
                  </td>

                  <td>
                    <div className='TOP__riht'>
                      <div className='this_TO_this'><East /></div>
                    </div>
                  </td>

                  <td>
                    <div className='id__from_token'>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip> {wallet__address}</Tooltip>}>
                        <Link to="#" className='link_tag'> <h5>  {(wallet__address?.length > 15) ? <h5>{wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 10, wallet__address?.length)}</h5> : <h5><Receipt />{wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 5, wallet__address?.length)}</h5>}</h5> </Link>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Copy Text </Tooltip>}
                      >
                        <button type='button' className='copy-token' onClick={() => { copyToClipboard(wallet__address, 'To copied successfully!') }}> <CopyAll />   </button>
                      </OverlayTrigger>
                    </div>
                  </td>

                  <td><h5>0.053090881<span className='ms-2'>{shortTitle}</span>	</h5>  </td>

                  <td>
                    <div className='trantion-area'>
                      <h5> 0</h5>
                    </div>
                  </td>
                </tr>


                <tr>
                  <td className='bg__td_td' >
                    <OverlayTrigger trigger="focus" placement="right" overlay={popover}>
                      <button className='eye__button'> <RemoveRedEye /></button>
                    </OverlayTrigger>
                  </td>

                  <td className='bg__td_td' >
                    <Link to="#" className='link_tag' > <h5> {(wallet__address?.length > 15) && wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 5, wallet__address?.length)}</h5></Link>
                  </td>

                  <td>

                    <OverlayTrigger
                      placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Transfer</Tooltip>} >
                      <Button className='button_d' >Transfer</Button>
                    </OverlayTrigger>

                  </td>

                  <td> <Link to="#" className='link_tag' ><h5> 36694663 </h5></Link></td>

                  <td>
                    <div className='times-area'>
                      <div className='time'> 5 Secs ago</div>
                      <div className='time-date'>2023-03-24 7:55:23	</div>
                    </div>
                  </td>

                  <td>
                    <div className='id__from_token'>


                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip className='copy__value'> {wallet__address}</Tooltip>}>
                        <Link to="#" className='link_tag' ><h5>{(wallet__address?.length > 15) && wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 10, wallet__address?.length)}</h5> </Link>

                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Copy Text</Tooltip>}
                      >
                        <button type='button' className='copy-token' onClick={() => { copyToClipboard(wallet__address, 'From copied successfully!') }}> <CopyAll />   </button>

                      </OverlayTrigger>
                    </div>
                  </td>

                  <td>
                    <div className='TOP__riht'>
                      <div className='this_TO_this'><East /></div>
                    </div>
                  </td>

                  <td>
                    <div className='id__from_token'>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip> {wallet__address}</Tooltip>}>
                        <Link to="#" className='link_tag'> <h5>  {(wallet__address?.length > 15) ? <h5>{wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 10, wallet__address?.length)}</h5> : <h5><Receipt />{wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 5, wallet__address?.length)}</h5>}</h5> </Link>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Copy Text </Tooltip>}
                      >
                        <button type='button' className='copy-token' onClick={() => { copyToClipboard(wallet__address, 'To copied successfully!') }}> <CopyAll />   </button>
                      </OverlayTrigger>
                    </div>
                  </td>

                  <td><h5>0.053090881<span className='ms-2'>{shortTitle}</span>	</h5>  </td>

                  <td>
                    <div className='trantion-area'>
                      <h5> 0</h5>
                    </div>
                  </td>
                </tr>


                <tr>
                  <td className='bg__td_td' >
                    <OverlayTrigger trigger="focus" placement="right" overlay={popover}>
                      <button className='eye__button'> <RemoveRedEye /></button>
                    </OverlayTrigger>
                  </td>

                  <td className='bg__td_td' >
                    <Link to="#" className='link_tag' > <h5> {(wallet__address?.length > 15) && wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 5, wallet__address?.length)}</h5></Link>
                  </td>

                  <td>

                    <OverlayTrigger
                      placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Transfer</Tooltip>} >
                      <Button className='button_d' >Transfer</Button>
                    </OverlayTrigger>

                  </td>

                  <td> <Link to="#" className='link_tag' ><h5> 36694663 </h5></Link></td>

                  <td>
                    <div className='times-area'>
                      <div className='time'> 5 Secs ago</div>
                      <div className='time-date'>2023-03-24 7:55:23	</div>
                    </div>
                  </td>

                  <td>
                    <div className='id__from_token'>


                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip className='copy__value'> {wallet__address}</Tooltip>}>
                        <Link to="#" className='link_tag' ><h5>{(wallet__address?.length > 15) && wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 10, wallet__address?.length)}</h5> </Link>

                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Copy Text</Tooltip>}
                      >
                        <button type='button' className='copy-token' onClick={() => { copyToClipboard(wallet__address, 'From copied successfully!') }}> <CopyAll />   </button>

                      </OverlayTrigger>
                    </div>
                  </td>

                  <td>
                    <div className='TOP__riht'>
                      <div className='this_TO_this'><East /></div>
                    </div>
                  </td>

                  <td>
                    <div className='id__from_token'>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip> {wallet__address}</Tooltip>}>
                        <Link to="#" className='link_tag'> <h5>  {(wallet__address?.length > 15) ? <h5>{wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 10, wallet__address?.length)}</h5> : <h5><Receipt />{wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 5, wallet__address?.length)}</h5>}</h5> </Link>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Copy Text </Tooltip>}
                      >
                        <button type='button' className='copy-token' onClick={() => { copyToClipboard(wallet__address, 'To copied successfully!') }}> <CopyAll />   </button>
                      </OverlayTrigger>
                    </div>
                  </td>

                  <td><h5>0.053090881<span className='ms-2'>{shortTitle}</span>	</h5>  </td>

                  <td>
                    <div className='trantion-area'>
                      <h5> 0</h5>
                    </div>
                  </td>
                </tr>


                <tr>
                  <td className='bg__td_td' >
                    <OverlayTrigger trigger="focus" placement="right" overlay={popover}>
                      <button className='eye__button'> <RemoveRedEye /></button>
                    </OverlayTrigger>
                  </td>

                  <td className='bg__td_td' >
                    <Link to="#" className='link_tag' > <h5> {(wallet__address?.length > 15) && wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 5, wallet__address?.length)}</h5></Link>
                  </td>

                  <td>

                    <OverlayTrigger
                      placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Transfer</Tooltip>} >
                      <Button className='button_d' >Transfer</Button>
                    </OverlayTrigger>

                  </td>

                  <td> <Link to="#" className='link_tag' ><h5> 36694663 </h5></Link></td>

                  <td>
                    <div className='times-area'>
                      <div className='time'> 5 Secs ago</div>
                      <div className='time-date'>2023-03-24 7:55:23	</div>
                    </div>
                  </td>

                  <td>
                    <div className='id__from_token'>


                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip className='copy__value'> {wallet__address}</Tooltip>}>
                        <Link to="#" className='link_tag' ><h5>{(wallet__address?.length > 15) && wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 10, wallet__address?.length)}</h5> </Link>

                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Copy Text</Tooltip>}
                      >
                        <button type='button' className='copy-token' onClick={() => { copyToClipboard(wallet__address, 'From copied successfully!') }}> <CopyAll />   </button>

                      </OverlayTrigger>
                    </div>
                  </td>

                  <td>
                    <div className='TOP__riht'>
                      <div className='this_TO_this'><East /></div>
                    </div>
                  </td>

                  <td>
                    <div className='id__from_token'>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip> {wallet__address}</Tooltip>}>
                        <Link to="#" className='link_tag'> <h5>  {(wallet__address?.length > 15) ? <h5>{wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 10, wallet__address?.length)}</h5> : <h5><Receipt />{wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 5, wallet__address?.length)}</h5>}</h5> </Link>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Copy Text </Tooltip>}
                      >
                        <button type='button' className='copy-token' onClick={() => { copyToClipboard(wallet__address, 'To copied successfully!') }}> <CopyAll />   </button>
                      </OverlayTrigger>
                    </div>
                  </td>

                  <td><h5>0.053090881<span className='ms-2'>{shortTitle}</span>	</h5>  </td>

                  <td>
                    <div className='trantion-area'>
                      <h5> 0</h5>
                    </div>
                  </td>
                </tr>


                <tr>
                  <td className='bg__td_td' >
                    <OverlayTrigger trigger="focus" placement="right" overlay={popover}>
                      <button className='eye__button'> <RemoveRedEye /></button>
                    </OverlayTrigger>
                  </td>

                  <td className='bg__td_td' >
                    <Link to="#" className='link_tag' > <h5> {(wallet__address?.length > 15) && wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 5, wallet__address?.length)}</h5></Link>
                  </td>

                  <td>

                    <OverlayTrigger
                      placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Transfer</Tooltip>} >
                      <Button className='button_d' >Transfer</Button>
                    </OverlayTrigger>

                  </td>

                  <td> <Link to="#" className='link_tag' ><h5> 36694663 </h5></Link></td>

                  <td>
                    <div className='times-area'>
                      <div className='time'> 5 Secs ago</div>
                      <div className='time-date'>2023-03-24 7:55:23	</div>
                    </div>
                  </td>

                  <td>
                    <div className='id__from_token'>


                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip className='copy__value'> {wallet__address}</Tooltip>}>
                        <Link to="#" className='link_tag' ><h5>{(wallet__address?.length > 15) && wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 10, wallet__address?.length)}</h5> </Link>

                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Copy Text</Tooltip>}
                      >
                        <button type='button' className='copy-token' onClick={() => { copyToClipboard(wallet__address, 'From copied successfully!') }}> <CopyAll />   </button>

                      </OverlayTrigger>
                    </div>
                  </td>

                  <td>
                    <div className='TOP__riht'>
                      <div className='this_TO_this'><East /></div>
                    </div>
                  </td>

                  <td>
                    <div className='id__from_token'>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip> {wallet__address}</Tooltip>}>
                        <Link to="#" className='link_tag'> <h5>  {(wallet__address?.length > 15) ? <h5>{wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 10, wallet__address?.length)}</h5> : <h5><Receipt />{wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 5, wallet__address?.length)}</h5>}</h5> </Link>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Copy Text </Tooltip>}
                      >
                        <button type='button' className='copy-token' onClick={() => { copyToClipboard(wallet__address, 'To copied successfully!') }}> <CopyAll />   </button>
                      </OverlayTrigger>
                    </div>
                  </td>

                  <td><h5>0.053090881<span className='ms-2'>{shortTitle}</span>	</h5>  </td>

                  <td>
                    <div className='trantion-area'>
                      <h5> 0</h5>
                    </div>
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </div>

          <div className='table__footer'>
            <div className='show_data'>
              <span>Show</span>
              <Form.Select aria-label="" id='table-data-options' onChange={(e: any) => setlimit(e.target.value)}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Form.Select>
              <span>Records</span>
            </div>

            <div className='pagination__sx'>
              <Pagination>
                <Pagination.First onClick={() => setpage(1)}> {"First"}</Pagination.First>
                <Pagination.Prev onClick={() => { page > 1 && setpage(page - 1) }} />pre
                <Pagination.Item disabled> Page {page} of 10000 </Pagination.Item>
                <Pagination.Next onClick={() => { totalPages >= page && setpage(page + 1) }} />
                <Pagination.Last onClick={() => setpage(totalPages)} >{"Last"}</Pagination.Last>
              </Pagination>
            </div>

          </div>
        </div>
      </div>



    </main>
  );
}

export default TransactionsAll;
