import React  from 'react';
import { CopyAll, KeyboardArrowLeft, KeyboardArrowRight, NorthEastRounded } from '@mui/icons-material';
import { Col, Form, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { shortTitle } from '../config/Config';
import SearchBar from './SearchBar';
// import _fetch from '../config/api';



const AddressView = () => {

    const wallet__address = '23424234234k234j32j42kj42344234';


    const copyToClipboard = (address: any, message: any) => {
        var textField = document.createElement('textarea')
        textField.innerText = address;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    return (
        <main className='main___next'>

            {/* sub header means header txt and searchbar */}

            <SearchBar titleTxt={'Address'}  address={wallet__address} addressData={true}/>


            <div className='card___section'>
                <Row>
                    <Col lg={3} sm={6}>
                        <div className='card trans__card_details'>
                            <p>network Utilization (24H)</p>
                            <h5>13.8%</h5>
                            <h6><NorthEastRounded /></h6>
                            <Link to="" className='stretched-link'>  </Link>

                        </div>
                    </Col>

                    <Col lg={3} sm={6}>
                        <div className='card trans__card_details'>
                            <p>Block Size</p>
                            <h5>100 Bytes</h5>
                            <h6><NorthEastRounded /></h6>
                            <Link to="" className='stretched-link'>  </Link>

                        </div>
                    </Col>



                    <Col lg={3} sm={6}>
                        <div className='card trans__card_details'>
                            <p> block rewards (24H)</p>
                            <h5>100 {shortTitle}</h5>
                            <h6><NorthEastRounded /></h6>
                            <Link to="" className='stretched-link'>  </Link>

                        </div>
                    </Col>


                    <Col lg={3} sm={6}>
                        <div className='card trans__card_details'>
                            <p>burnt fee (24H)</p>
                            <h5>5,177,722</h5>
                            <h6><NorthEastRounded /></h6>
                            <Link to="" className='stretched-link'>  </Link>

                        </div>
                    </Col>
                </Row>
            </div>

            {/* table data starting */}

            <div className='table__box'>
                <div className='table__outer table__card'>
                    <div className='table__outer__header'>
                        <div className='table__txt-header'>
                            <h5 className=''>Total of 36,702,112 blocks</h5>
                            <p>( Showing the last 500k records )</p>
                        </div>

                        <div className="pagination__sx">
                            <ul className="pagination">
                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        <span aria-hidden="true"> First</span>
                                        <span className="visually-hidden">First</span>
                                    </Link>
                                </li>
                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        <span aria-hidden="true"> <KeyboardArrowLeft /></span><span className="visually-hidden">Previous</span>
                                    </Link>
                                </li>

                                <li className="page-item disabled"><span className="page-link"> Page 1 of 10000 </span></li>

                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        <span> <KeyboardArrowRight /></span><span className="visually-hidden">Next</span>
                                    </Link>
                                </li>

                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        <span>Last</span><span className="visually-hidden">Last</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div className=''>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Block</th>
                                    <th>Age</th>
                                    <th>Txn</th>
                                    <th>Validator</th>
                                    <th>Gas Used</th>
                                    <th>Gas Limit	</th>
                                    <th>Reward</th>
                                    <th>Burnt Fees ({shortTitle})</th>
                                </tr>
                            </thead>

                            <tbody className='blocks_list'>


                                <tr>
                                    <td> <Link to="#" className='link_tag' ><h5> 36694663 </h5></Link></td>

                                    <td>
                                        <div className='times-area'>
                                            <div className='time'> 5 Secs ago</div>
                                        </div>
                                    </td>

                                    <td><h5>0.05801</h5> </td>

                                    <td>
                                        <div className='id__from_token'>

                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip className='copy__value'> {wallet__address}</Tooltip>}>
                                                <Link to="#" className='link_tag' ><h5> Validator: Alan Turing</h5> </Link>

                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip>Copy Text</Tooltip>}>
                                                <button type='button' className='copy-token' onClick={() => { copyToClipboard(wallet__address, 'From copied successfully!') }}> <CopyAll />   </button>
                                            </OverlayTrigger>
                                        </div>
                                    </td>

                                    <td><h5>12%</h5> </td>
                                    <td> <h5>138,908,389	</h5> </td>
                                    <td><h5>0.053090881<span className='ms-2'>{shortTitle}</span>	</h5>  </td>
                                    <td><h5>0.053090881<span className='ms-2'>{shortTitle}</span>	</h5>  </td>

                                </tr>

                                <tr>
                                    <td> <Link to="#" className='link_tag' ><h5> 36694663 </h5></Link></td>

                                    <td>
                                        <div className='times-area'>
                                            <div className='time'> 5 Secs ago</div>
                                        </div>
                                    </td>

                                    <td><h5>0.05801</h5> </td>

                                    <td>
                                        <div className='id__from_token'>

                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip className='copy__value'> {wallet__address}</Tooltip>}>
                                                <Link to="#" className='link_tag' ><h5> Validator: Alan Turing</h5> </Link>

                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip>Copy Text</Tooltip>}>
                                                <button type='button' className='copy-token' onClick={() => { copyToClipboard(wallet__address, 'From copied successfully!') }}> <CopyAll />   </button>
                                            </OverlayTrigger>
                                        </div>
                                    </td>

                                    <td><h5>12%</h5> </td>
                                    <td> <h5>138,908,389	</h5> </td>
                                    <td><h5>0.053090881<span className='ms-2'>{shortTitle}</span>	</h5>  </td>
                                    <td><h5>0.053090881<span className='ms-2'>{shortTitle}</span>	</h5>  </td>

                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <div className='table__footer'>
                        <div className='show_data'>
                            <span>Show</span>
                            <Form.Select aria-label="" id='table-data-options'>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </Form.Select>
                            <span>Records</span>
                        </div>

                        <div className="pagination__sx">
                            <ul className="pagination">
                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        <span aria-hidden="true"> First</span>
                                        <span className="visually-hidden">First</span>
                                    </Link>
                                </li>
                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        <span aria-hidden="true"> <KeyboardArrowLeft /></span><span className="visually-hidden">Previous</span>
                                    </Link>
                                </li>

                                <li className="page-item disabled"><span className="page-link"> Page 1 of 10000 </span></li>

                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        <span> <KeyboardArrowRight /></span><span className="visually-hidden">Next</span>
                                    </Link>
                                </li>

                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        <span>Last</span><span className="visually-hidden">Last</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>



        </main>
    );
}

export default AddressView;
