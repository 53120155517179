import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AscTimestampTimer, api_url, shortTitle } from '../../../config/Config';
import { Description, East } from '@mui/icons-material';
import Skeleton from 'react-loading-skeleton';
import _fetch from '../../../config/api';


const Transactions = () => {

    const [loader, setloader] = useState(true);

    const [getTransactions, setgetTransactions] = useState([])
    const getTransactionData = async () => {
        setloader(true)
        let data = await _fetch(`${api_url}transaction/allTransactions?limit=6&page=1`, "GET", {})
        if (data?.status === "success") {
            setgetTransactions(data?.data?.transactions);
            setloader(false);

        }
    }

    useEffect(() => {
        getTransactionData();
        // const timer = setTimeout(() => {
        //     setloader(false);
        // }, 5000); // 5 seconds in milliseconds

        // return () => clearTimeout(timer);
    }, []);



    return (
        <div className='card default_card tabes__card'>
            <div className='card-header'>
                <h5>Latest Transactions</h5>
            </div>

            <div className='card-body'>
                {loader && <ul className='table__list'>
                    <li>
                        <div className='i_first'>

                            <Skeleton
                                circle
                                height="100%"
                                containerClassName="avatar-skeleton avatar-skeleton2"
                            />



                            <div className='i_pragraph'>
                                <Skeleton count={2}
                                    containerClassName="table__6__"
                                    className="home_skeleton__hn"
                                />
                            </div>
                        </div>


                        <div className='i_middle'>
                            <Skeleton count={2}
                                containerClassName="table__6__"
                                className="home_skeleton__hn home_skeleton__center"
                            />

                        </div>

                        <div className='i_last'>

                            <Skeleton count={1}
                                containerClassName="table__6__"
                                className="home_skeleton__hn"
                            />

                        </div>
                    </li>
                    <li>
                        <div className='i_first'>

                            <Skeleton
                                circle
                                height="100%"
                                containerClassName="avatar-skeleton avatar-skeleton2"
                            />



                            <div className='i_pragraph'>
                                <Skeleton count={2}
                                    containerClassName="table__6__"
                                    className="home_skeleton__hn"
                                />
                            </div>
                        </div>


                        <div className='i_middle'>
                            <Skeleton count={2}
                                containerClassName="table__6__"
                                className="home_skeleton__hn home_skeleton__center"
                            />

                        </div>

                        <div className='i_last'>

                            <Skeleton count={1}
                                containerClassName="table__6__"
                                className="home_skeleton__hn"
                            />

                        </div>
                    </li>
                    <li>
                        <div className='i_first'>

                            <Skeleton
                                circle
                                height="100%"
                                containerClassName="avatar-skeleton avatar-skeleton2"
                            />



                            <div className='i_pragraph'>
                                <Skeleton count={2}
                                    containerClassName="table__6__"
                                    className="home_skeleton__hn"
                                />
                            </div>
                        </div>


                        <div className='i_middle'>
                            <Skeleton count={2}
                                containerClassName="table__6__"
                                className="home_skeleton__hn home_skeleton__center"
                            />

                        </div>

                        <div className='i_last'>

                            <Skeleton count={1}
                                containerClassName="table__6__"
                                className="home_skeleton__hn"
                            />

                        </div>
                    </li>
                    <li>
                        <div className='i_first'>

                            <Skeleton
                                circle
                                height="100%"
                                containerClassName="avatar-skeleton avatar-skeleton2"
                            />



                            <div className='i_pragraph'>
                                <Skeleton count={2}
                                    containerClassName="table__6__"
                                    className="home_skeleton__hn"
                                />
                            </div>
                        </div>


                        <div className='i_middle'>
                            <Skeleton count={2}
                                containerClassName="table__6__"
                                className="home_skeleton__hn home_skeleton__center"
                            />

                        </div>

                        <div className='i_last'>

                            <Skeleton count={1}
                                containerClassName="table__6__"
                                className="home_skeleton__hn"
                            />

                        </div>
                    </li>
                    <li>
                        <div className='i_first'>

                            <Skeleton
                                circle
                                height="100%"
                                containerClassName="avatar-skeleton avatar-skeleton2"
                            />



                            <div className='i_pragraph'>
                                <Skeleton count={2}
                                    containerClassName="table__6__"
                                    className="home_skeleton__hn"
                                />
                            </div>
                        </div>


                        <div className='i_middle'>
                            <Skeleton count={2}
                                containerClassName="table__6__"
                                className="home_skeleton__hn home_skeleton__center"
                            />

                        </div>

                        <div className='i_last'>

                            <Skeleton count={1}
                                containerClassName="table__6__"
                                className="home_skeleton__hn"
                            />

                        </div>
                    </li>
                    <li>
                        <div className='i_first'>

                            <Skeleton
                                circle
                                height="100%"
                                containerClassName="avatar-skeleton avatar-skeleton2"
                            />



                            <div className='i_pragraph'>
                                <Skeleton count={2}
                                    containerClassName="table__6__"
                                    className="home_skeleton__hn"
                                />
                            </div>
                        </div>


                        <div className='i_middle'>
                            <Skeleton count={2}
                                containerClassName="table__6__"
                                className="home_skeleton__hn home_skeleton__center"
                            />

                        </div>

                        <div className='i_last'>

                            <Skeleton count={1}
                                containerClassName="table__6__"
                                className="home_skeleton__hn"
                            />

                        </div>
                    </li>




                </ul>}

                {!loader && <ul className='table__list'>
                    {getTransactions?.map((val:any) => (
                        <li>
                            <div className='i_first'>

                                <>
                                    <div className='icon__first'>
                                        <Description />
                                    </div>
                                </>



                                <div className='i_pragraph'>


                                    <>
                                        <p> <Link to={"/tx/" + val?.hash} className='inner__link'> {(val?.hash && val?.hash?.length > 15) && (val?.hash?.substring(0, 5) + '....' + val?.hash?.substring(val?.hash?.length - 5))}</Link></p>
                                        <p> {AscTimestampTimer(val?.timeStamp,val?.blockNumber+'tx')} <span id={val?.blockNumber+'tx'}></span></p>

                                    </>





                                </div>
                            </div>


                            <div className='i_middle'>


                                <>
                                    <p> From
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    <p className='txt-nowrap mb-0'>{val?.from}</p>
                                                </Tooltip>
                                            }>
                                            <Link className='inner__link ms-1' to="#">{(val?.from && val?.from?.length > 15) && (val?.from?.substring(0, 5) + '....' + val?.from?.substring(val?.from?.length - 5))}</Link>
                                        </OverlayTrigger>
                                    </p>
                                    <p> To
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    <p className='txt-nowrap mb-0'>{val?.to}</p>
                                                </Tooltip>
                                            }>
                                            <Link className='inner__link ms-1' to="#">{(val?.to && val?.to?.length > 15) && (val?.to?.substring(0, 5) + '....' + val?.to?.substring(val?.to?.length - 5))}</Link>
                                        </OverlayTrigger>
                                    </p>
                                </>



                            </div>

                            <div className='i_last'>


                                <>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>Amount</Tooltip>
                                        }>
                                        <p>{parseInt(val?.value) / 1000000000000000000} {shortTitle}</p>
                                    </OverlayTrigger>
                                </>



                            </div>
                        </li>
                    ))}
                </ul>
                }
            </div>

            <div className='card-footer'>
                <Link to="/txs">VIEW ALL TRANSACTIONS <East /> </Link>
            </div>
        </div>
    );
}

export default Transactions;
