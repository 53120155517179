import { ArrowDropDown } from '@mui/icons-material';
import React from 'react';
import { Badge, Form, ListGroup } from 'react-bootstrap';
import { shortTitle } from '../../../config/Config';
import { Link } from 'react-router-dom';

const Animation = () => {
    return (
        <section className='top-section-overview'>
            <div className='animation_contianer'>
                <div className='star_main'>
                    <div className="star___star"></div>
                </div>

                <div className='THECD676'></div>
                <div className='LEft___S'></div>
                <div className='LEFT__SDD'></div>
                <div className='LEFT__STAR'></div>

                <div className='Rocket__ani'></div>
                <div className='Rocket__ani_2'></div>


                <div className='d-flex justify-content-between bg__SDS'>
                    <div className='bg__star'>
                        <div className='star___droping'>
                            <div className='LEFT__STAR LEFT__STAR_2 LEFT__STAR_relative ' style={{ left: '-40px' }}></div>
                        </div>
                        <div className='star___droping'>
                            <div className='LEFT__STAR LEFT__STAR_2 ' style={{ left: '-100px', top: '-70px' }}></div>
                        </div>

                        <div className='star___droping'>
                            <div className='LEFT__STAR LEFT__STAR_2 ' style={{ left: '100px', top: '-90px' }}></div>
                        </div>

                        <div className='star___droping'>
                            <div className='LEFT__STAR LEFT__STAR_2 ' style={{ left: '100px', top: '-90px' }}></div>
                        </div>

                        <div className='star___droping'>
                            <div className='LEFT__STAR LEFT__STAR_2 ' style={{ left: '100px', top: '-90px' }}></div>
                        </div>


                        <div className='star___droping'>
                            <div className='LEFT__STAR LEFT__STAR_2 ' style={{ left: '100px', top: '-90px' }}></div>
                        </div>

                        <div className='star___droping'>
                            <div className='LEFT__STAR LEFT__STAR_2 ' style={{ left: '100px', top: '-90px' }}></div>
                        </div>

                        <div className='star___droping'>
                            <div className='LEFT__STAR LEFT__STAR_2 ' style={{ left: '100px', top: '-90px' }}></div>
                        </div>

                        <div className='star___droping'>
                            <div className='LEFT__STAR LEFT__STAR_2 delay__data' style={{ left: '100px', top: '-90px' }}></div>
                        </div>

                    </div>

                </div>
            </div>



            <div className='new__animation_data'>
                <div className='overview-sec left-top-ad'>
                    <h2>  {shortTitle} Smart Chain Explorer </h2>
                </div>

                <div className='overview-sec right-top-sec'>

                    <Form>
                        <div className='form___outer'>

                            <div className='INpu__searhc'>
                                <span> <ArrowDropDown /> </span>
                                <input placeholder="All Filter" className='input__sarch_type' />


                                <div className='search-type'>
                                <ListGroup>
                                        <ListGroup.Item> All Filters</ListGroup.Item>
                                        <ListGroup.Item>Address</ListGroup.Item>
                                        <ListGroup.Item>Block</ListGroup.Item>
                                        <ListGroup.Item>Transaction Hash</ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </div>

                            <Form.Group className="form-group" >
                                <Form.Control type="search" className='input-a' id='search_ID' placeholder='Search by Chain, Address, Validator info, and TxHash' /* autoComplete='off' */ />

                                <div className='search-dropdown'>
                                    <ListGroup>
                                        <Link to="/" className="list-group-item list-group-item-action"> AdsTulip NFT: Free $LAND AirDrop - Mint Rare Tulip NFT <Badge className='badege-link'>Sponsored</Badge> </Link>
                                        <ListGroup.Item>Porta ac consectetur ac</ListGroup.Item>
                                        <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
                                    </ListGroup>
                                </div>


                            </Form.Group>
                        </div>
                    </Form>

                </div>
            </div>

        </section>
    );
}

export default Animation;
