import site_logo from '../Assets/Icon/Logo.png';
import site_logo_dark from '../Assets/Icon/dark_logo.png';
import site_short_logo from '../Assets/Icon/ShortLogo.png';
export const logo = site_logo;
export const dark_logo = site_logo_dark;
export const shortLogo = site_short_logo;
export const title = 'GTC SCAN';
export const websiteName = 'https://gtcscan.io';
export const shortTitle = 'GTC';
export const titleDescription = 'GTC SCAN';
export const api_url = 'https://api.gtcscan.io/api/v1/'; // live
export const gasCalculate = (gasVal: any, gasPrice: any) => {
    const getCal = gasVal * gasPrice;
    const getCal2 = getCal / 18000000000000000000;
    return (getCal2 * 1800)
}

export function timeSince(date: any) {
    let newDate: any = new Date();
    var seconds = Math.floor((newDate - (date * 1000)) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}

export const timestampToDate = (val: any) => {

    return (new Date(Number(val + `000`))).toUTCString().replace("GMT", "+ UTC");
}

export const AscTimestampTimer = (stamp: any, id: any) => {
    var data: any = '';
    // clearInterval(interval);
    // clearInterval(aTimerS);
    const interval = window.setInterval(() => {
        const currentTime = new Date().getTime();
        const targetTime = new Date(stamp * 1000).getTime(); // Change this to your target timestamp
        const remainingTime = currentTime - targetTime;
        const days: any = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        const hours: any = Math.floor(
            (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes: any = Math.floor(
            (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds: any = Math.floor((remainingTime % (1000 * 60)) / 1000);
        var countdownText: any = "";

        if (days > 0) {
            countdownText = (days > '0' ? `${days} days ` : '0 days') + '' + (hours > 0 ? `${hours} hrs ` : '0 hrs') + ' ago';
        } else if (days === 0 && hours > 0) {
            countdownText = (`${hours} hrs `) + '' + (minutes > 0 ? `${minutes} mins ` : '0 mins') + ' ago';
        } else if (days === 0 && hours === 0 && minutes === 1) {
            countdownText = (minutes > 0 ? `${minutes} min ` : '0 min') + '' + (seconds > 0 ? `${seconds} secs` : '0 secs') + ' ago';
        } else if (days === 0 && hours === 0 && minutes > 0) {
            countdownText = (minutes > 0 ? `${minutes} mins ` : '0 mins') + '' + (seconds > 0 ? `${seconds} secs` : '0 secs') + ' ago';
        } else if (days === 0 && hours === 0) {
            countdownText = (seconds > 0 ? `${seconds} secs` : '0 secs') + ' ago';
        }

        // countdownText =  (days > '0' ? `${days} day ` : '0 day') + '' + (`${hours} hour `) + '' + (minutes > 0 ? `${minutes} mins ` : '') + '' + (seconds > 0 ? `${seconds} secs` : '0 secs');

        let myContainer = document.getElementById(id) as HTMLInputElement;
        if (myContainer !== null) {
            myContainer.innerHTML = countdownText;

        }
        // return countdownText;

    }, 1000);

    // let stop = () => clearInterval(interval);
    return data;
}