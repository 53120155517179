import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import { api_url, shortTitle } from '../../../config/Config';
import _fetch from '../../../config/api';



const Chartusage = () => {

  const [chartData, setChartData] = useState([]);
  const getChargeData = async () => {
    let data = await _fetch(`${api_url}chart/totalTransactions`, "GET", {})
    if (data?.status === "success") {
      setChartData(data?.data);
    }
  }

    // 2 area chart
    const areachart = {
        series: [{
            name: 'GTC Transaction',
            data:[]
          }],
          options: {
            colors:['#ae67d8' , '#ffc65b' ],
            chart: {
              id: 'area-datetime',
              type: 'area',
              stacked: false,
              height: 280,
              zoom: {
                // type: 'x',
                // enabled: true,
                autoScaleYaxis: true
              },
              toolbar: {
                show: false
              }
            },
            // annotations: {
            //   yaxis: [{
            //     y: 30,
            //     borderColor: '#999',
            //     label: {
            //       show: true,
            //       text: 'Transactions',
            //       style: {
            //         color: "#fff",
            //         background: '#00E396'
            //       }
            //     }
            //   }]},
            // dataLabels: {
            //   enabled: false
            // },
            stroke: {
              // curve: 'stepline',
              
            },
            // markers: {
            //   size: 0,
            // },
            // title: {
            //   text: 'GTC Overview',
            //   align: 'left'
            // },
            fill: {
              type: 'gradient',
              gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.8,
                opacityTo: 0.7,
                stops: [50, 100, 40]
              },
            },
            grid: {
                show: true,
                borderColor: '#f0ebeb4d',
                strokeDashArray: 0,
                position: 'back',
            },
            annotations: {
              yaxis: [{
                y: 30,
                borderColor: '#999',
                
              }],
              
            },
            dataLabels: {
              enabled: false
            },
            markers: {
              size: 0,
              style: 'hollow',
            },
            xaxis: {
              type: 'datetime',
              min: new Date('12 Apr 2023').getTime(),
              tickAmount: 6,
            },
            
            tooltip: {
              x: {
                format: 'dd MMM yyyy'
              }
            },
            selection: 'one_year',

          },
    };
    const [chartarea, setChartarea] = useState(areachart);
    
    useEffect(() => { 
      getChargeData();
    }, []);


    return (
        <div className='card default_card chart__card_sx'>
          <p> {shortTitle} Overview</p>
            <div>
                <ReactApexChart
                    options={chartarea.options as ApexOptions}
                    series={[{
                      name: 'GTC Transaction',
                      data:chartData
                    }]}
                    type="area"
                    height={376}
                />
            </div>
        </div>
    );
}

export default Chartusage;