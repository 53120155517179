import React, { useEffect, useState } from "react";
import { ArrowDropDown, Menu, NightsStay, WbSunny } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { dark_logo, logo, shortLogo, shortTitle, title } from "../config/Config";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Accordion } from "react-bootstrap";


const Header = () => {
    const location = useLocation();



    const HeaderData = [
        {
            LinkName: "Home",
            LinkUrl: '/',
            dropdown: null
        },
        {
            LinkName: "Blockchain",
            LinkUrl: '',
            dropdown: [
                {
                    dropLinkName: "Transactions",
                    dropLinkUrl: '/txs'
                },

                {
                    dropLinkName: "Blocks",
                    dropLinkUrl: '/blocks'
                },
                {
                    dropLinkName: "Contract Transactions",
                    dropLinkUrl: '/txscontract'
                },
                {
                    dropLinkName: "Top Accounts",
                    dropLinkUrl: '/'
                },
            ],
        },
        {
            LinkName: "Resources",
            LinkUrl: '',
            dropdown: [
                {
                    dropLinkName: "Join GTC Network",
                    dropLinkUrl: '/'
                },
                {
                    dropLinkName: "Charts & Stats",
                    dropLinkUrl: '/'
                },
                {
                    dropLinkName: "Develpers Api",
                    dropLinkUrl: '/'
                }

            ],
        },
        {
            LinkName: " Tokens ",
            LinkUrl: '',
            dropdown: [
                {
                    dropLinkName: "Tokens",
                    dropLinkUrl: '/'
                },
                {
                    dropLinkName: "Tokens Transactions",
                    dropLinkUrl: '/'
                },
            ],
        },
        {
            LinkName: "Sign in",
            LinkUrl: '/register',
            dropdown: null
        },
    ];


    const [open2, setonOpenModal2] = useState(false);

    const onOpenModal2 = () => setonOpenModal2(true);
    const onCloseModal2 = () => setonOpenModal2(false);



    const [theme, setTheme] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        return savedTheme ? savedTheme : 'light';
    });

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
        document.body.dataset.theme = newTheme;
    };

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            setTheme(savedTheme);
            document.body.dataset.theme = savedTheme;
        }
    }, []);



  useEffect(() => {

    if (location.pathname === "/") {
      document.body.setAttribute("HomeHeader", "true");
    } else {
      document.body.removeAttribute("HomeHeader");
    }
    window.scrollTo(0, 0);

  }, [location.pathname]);


    return (
        <>
            <header>
                <div className="main_continer">
                    <div className="left-header">
                        <Link to="/" className="navbar-logo">
                            <img src={logo} alt={title + "logo"} width="100%" /> {/* these logo only show on homepage ( i means banner section ) */}
                            <img src={theme === 'light' ? dark_logo :  logo} alt={title + "logo"} width="100%" /> {/* this logo more without banner section */}
                        </Link>

                        <div className="token__sx">
                            <img src={shortLogo} alt={title + 'short_logo'} />
                            <p>$110.42 -1.65%</p>
                        </div>
                    </div>

                    <button onClick={onOpenModal2} type="button" className="btn toggle-button"><Menu /></button>


                    <div className="right-header">
                        <ul className="nav align-items-center">

                            {HeaderData.map((item: any, index: any) => (

                                <li className="nav-item" key={index}>
                                    {item.dropdown == null && <Link to={item.LinkUrl} className="nav-link">{item.LinkName} </Link>}

                                    {item.dropdown && (
                                        <div className="dropdown__sx">
                                            <p className="nav-link"> {item.LinkName}  <span><ArrowDropDown /></span></p>

                                            <div className="dropdown_menu_sx">
                                                <ol className="dropdown-ol">
                                                    {item.dropdown.map((subItem: any) => (
                                                        <Link key={subItem.dropLinkName} className="dropdown-item" to={subItem.dropLinkUrl}>{subItem.dropLinkName} </Link>
                                                    ))}
                                                </ol>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>

                        <div className="right__end__sx">

                            <button className="theme__button btn" onClick={toggleTheme} type="button">
                                {
                                    theme === 'light' ? <span className="moon__icon"><NightsStay /></span> : <span className="sun__icon"><WbSunny /></span>
                                }
                                
                            </button>

                            <div className="dropdown__tk">
                                <div className="tk__logo">
                                    <img src={shortLogo} alt="gtc_exchange" />
                                </div>

                                <div className="dropdown_menu_sx">
                                    <ul className="nav___isd">
                                        <li>
                                            <h5> Network details</h5>
                                        </li>
                                        <li>
                                            <p>Network Name</p>
                                            <p> Smart chain</p>
                                        </li>

                                        <li>
                                            <p>New RPC URL</p>
                                            <p> https://gtcscan.io/</p>
                                        </li>

                                        <li>
                                            <p>chain ID</p>
                                            <p> 56 </p>
                                        </li>
                                        <li>
                                            <p>Currency Symbol</p>
                                            <p> {shortTitle} </p>
                                        </li>

                                        <li>
                                            <Link to='#' className="btn"> Add {shortTitle} Network </Link>

                                        </li>

                                    </ul>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </header>





            <Modal open={open2} onClose={onCloseModal2}
                classNames={{
                    modal: "HomeMenu__bv",
                    modalAnimationIn: 'HomeMenuAnimation',
                    modalAnimationOut: 'HomeMenuAnimationLeaveAnimation',
                }}>
                <div className="phone-view-header">
                    <div className="logo__sc">
                        <img src={logo} alt={title + 'logo'} />
                    </div>
                    <ul className="nav flex-column">
                        <Accordion defaultActiveKey="0">
                            {HeaderData.map((item: any, index: any) => (
                                <li className="nav-item" key={index}>
                                    {item.dropdown == null && (
                                        <Link
                                            to={item?.LinkUrl}
                                            className="nav-link"
                                            onClick={onCloseModal2}>
                                            {item.LinkName}
                                        </Link>
                                    )}
                                    {item.dropdown && (
                                        <ul className="p-0">
                                            <Accordion.Item eventKey="">
                                                <Accordion.Header>
                                                    {' '}
                                                    <span>{item.LinkName} </span>{' '}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <ul className="p-0">
                                                        {item.dropdown.map((subItem: any) => (
                                                            <Link
                                                                className="nav-link nav_link_after"
                                                                key={subItem.dropLinkName}
                                                                to={subItem.dropLinkUrl}
                                                                onClick={onCloseModal2} >
                                                                {subItem.dropLinkName}
                                                            </Link>
                                                        ))}
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </Accordion>
                    </ul>
                </div>
            </Modal>

        </>
    )
}

export default Header