import React from 'react'
import Animation from './Components/Animation'
import { Col, Row } from 'react-bootstrap'
import Chartusage from './Components/Chartusage'
// import { TickerTape } from "react-ts-tradingview-widgets";
import Information from './Components/Information';
import Blocks from './Components/Blocks';
import Transactions from './Components/Transactions';


const Index = () => {

  
  return (
    <>
      <main>
        <Animation />



        <section className='chart__sec'>
          <Row className='main__row'>
            <Col sm={12}>
              <div className='card default_card chart___iframe'>
                {/* <TickerTape></TickerTape> */}
                <iframe  src="https://www.tradingview-widget.com/embed-widget/ticker-tape/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22FOREXCOM%3ASPXUSD%22%2C%22title%22%3A%22S%26P%20500%20Index%22%7D%2C%7B%22proName%22%3A%22FOREXCOM%3ANSXUSD%22%2C%22title%22%3A%22US%20100%20Cash%20CFD%22%7D%2C%7B%22proName%22%3A%22FX_IDC%3AEURUSD%22%2C%22title%22%3A%22EUR%20to%20USD%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22Bitcoin%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3AETHUSD%22%2C%22title%22%3A%22Ethereum%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22isTransparent%22%3Atrue%2C%22displayMode%22%3A%22adaptive%22%2C%22colorTheme%22%3A%22dark%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A76%2C%22utm_source%22%3A%22www.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22ticker-tape%22%2C%22page-uri%22%3A%22www.tradingview.com%2Fwidget-wizard%2Fen%2Fdark%2Fticker-tape%2F%22%7D" title="ticker tape TradingView widget" lang="en"></iframe>
              </div>
            </Col>
            <Col lg={6}>
              <Chartusage />
            </Col>

            <Col lg={6}>
              <Information/>
            </Col>

            <Col lg={6}>
              <Blocks />
            </Col>

            <Col lg={6}>
              <Transactions/>
            </Col>
          </Row>
        </section>
      </main>
    </>
  )
}

export default Index