import React from 'react';
import { CheckCircleRounded, CopyAll, DescriptionRounded, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { shortTitle } from '../config/Config';
import SearchBar from './SearchBar';
import Skeleton from 'react-loading-skeleton';




const ContractTnx = () => {

    const wallet__address = '0x9ab76ac67bd31404d025b02c2748a90d37b25457a844243051';




    const copyToClipboard = (address: any, message: any) => {
        var textField = document.createElement('textarea')
        textField.innerText = address;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }



    return (
        <main className='main___next'>

            {/* sub header means header txt and searchbar */}

            <SearchBar titleTxt={'Contract Transactions'} />



            {/* table data starting */}

            <div className='table__box'>
                <div className='table__outer table__card'>
                    <div className='table__outer__header'>
                        <div className='table__txt-header'>
                            <h5 className=''>A total of 2,124,833,587 Contract transactions found</h5>
                            <p>( Showing the last 10k records only )</p>
                        </div>

                        <div className="pagination__sx">
                            <ul className="pagination">
                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        <span aria-hidden="true"> First</span>
                                        <span className="visually-hidden">First</span>
                                    </Link>
                                </li>
                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        <span aria-hidden="true"> <KeyboardArrowLeft /></span><span className="visually-hidden">Previous</span>
                                    </Link>
                                </li>

                                <li className="page-item disabled"><span className="page-link"> Page 1 of 10000 </span></li>

                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        <span> <KeyboardArrowRight /></span><span className="visually-hidden">Next</span>
                                    </Link>
                                </li>

                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        <span>Last</span><span className="visually-hidden">Last</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div className=''>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Block</th>
                                    <th>Age</th>
                                    <th>Parent Txn Hash	</th>
                                    <th>Type</th>
                                    <th>From</th>
                                    <th>to</th>
                                    <th>value</th>
                                </tr>
                            </thead>

                            <tbody className='blocks_list'>

                                <tr>
                                    <td colSpan={10}>
                                        <Skeleton count={10}
                                            containerClassName="MainTable-skeleton"
                                            className="skeleton__lines"
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td> <Link to="#" className='link_tag' ><h5> 36707088 </h5></Link></td>

                                    <td>
                                        <div className='times-area'>
                                            <div className='time'> 5 Secs ago</div>
                                            {/* <div className='time-date'>2023-03-24 7:55:23	</div> */}
                                        </div>
                                    </td>


                                    <td>
                                        <div className='id__from_token'>

                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip className='copy__value'> {wallet__address}</Tooltip>}>
                                                <Link to="#" className='link_tag' ><h5> <CheckCircleRounded />  {(wallet__address?.length > 15) && wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 10, wallet__address?.length)}</h5> </Link>

                                            </OverlayTrigger>
                                        </div>
                                    </td>

                                    <td><h5>Call</h5> </td>

                                    <td>
                                        <div className='id__from_token'>

                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip className='copy__value'> {wallet__address}</Tooltip>}>
                                                <Link to="#" className='link_tag' ><h5> <DescriptionRounded /> {(wallet__address?.length > 15) && wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 10, wallet__address?.length)}</h5> </Link>

                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip>Copy Text</Tooltip>}>
                                                <button type='button' className='copy-token' onClick={() => { copyToClipboard(wallet__address, 'From copied successfully!') }}> <CopyAll />   </button>
                                            </OverlayTrigger>
                                        </div>
                                    </td>

                                    <td>
                                        <div className='id__from_token'>

                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip className='copy__value'> {wallet__address}</Tooltip>}>
                                                <Link to="#" className='link_tag' ><h5> <DescriptionRounded /> {(wallet__address?.length > 15) && wallet__address?.substring(0, 5) + '....' + wallet__address?.substring(wallet__address?.length - 10, wallet__address?.length)}</h5> </Link>

                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip>Copy Text</Tooltip>}>
                                                <button type='button' className='copy-token' onClick={() => { copyToClipboard(wallet__address, 'From copied successfully!') }}> <CopyAll />   </button>
                                            </OverlayTrigger>
                                        </div>
                                    </td>

                                    <td><h5>0.053090881<span className='ms-2'>{shortTitle}</span>	</h5>  </td>

                                </tr>


                            </tbody>
                        </Table>
                    </div>

                    <div className='table__footer'>
                        <div className='show_data'>
                            <span>Show</span>
                            <Form.Select aria-label="" id='table-data-options'>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </Form.Select>
                            <span>Records</span>
                        </div>

                        <div className="pagination__sx">
                            <ul className="pagination">
                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        <span aria-hidden="true"> First</span>
                                        <span className="visually-hidden">First</span>
                                    </Link>
                                </li>
                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        <span aria-hidden="true"> <KeyboardArrowLeft /></span><span className="visually-hidden">Previous</span>
                                    </Link>
                                </li>

                                <li className="page-item disabled"><span className="page-link"> Page 1 of 10000 </span></li>

                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        <span> <KeyboardArrowRight /></span><span className="visually-hidden">Next</span>
                                    </Link>
                                </li>

                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        <span>Last</span><span className="visually-hidden">Last</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>



        </main>
    );
}

export default ContractTnx;
