import { CopyAll, QrCode, Search } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

const SearchBar = (props: any) => {


  const [scrollClass, setScrollClass] = useState('');


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 132) {
        setScrollClass('stickyTop_j');
      } else {
        setScrollClass('');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`trans__header ${scrollClass}`}>
      <div className='trans__header__jdhf'>
        <h3 className='trans__header_txt'> {props.titleTxt} / <span className='address__as'>{props?.address}</span> </h3>

        {
          props.addressData ?
            <>
              <button type='button' className='copy__btn_searchbar btn'><CopyAll /></button>
              <button type='button' className='copy__btn_searchbar btn'><QrCode /></button>
            </>
            : ''
        }

      </div>

      <div className='search__bar'>
        <form>
          <input type='search' className='form-control' placeholder='Search transactions, blocks, programs and tokens' />
          <button type='button' className='btn search__btn'> <Search /> </button>
        </form>
      </div>
    </div>
  );
}

export default SearchBar;
