import React from 'react'
import { Link } from 'react-router-dom';
import img___dd from '../Assets/Images/error_bg.png'

const Error = () => {



  return (
    <main>
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <img src={img___dd} width="100%" alt="error-img" className='error-img'/>
                    <h1>Oops!</h1>
                </div>
                <h2>404 - Search not found</h2>
                <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                <Link to="/">Go To Homepage</Link>
            </div>
        </div>
    </main>
  )
}

export default Error